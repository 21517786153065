import * as THREE from 'three'
import { CuboidCollider ,RigidBody } from '@react-three/rapier'
import { useMemo , useState ,useRef } from 'react'
import { useFrame } from '@react-three/fiber'
import { Float, Text3D, useGLTF } from '@react-three/drei'

THREE.ColorManagement.legacyMode = false

const simpleBox = new THREE.BoxGeometry(1,1,1)

const floor1Material = new THREE.MeshStandardMaterial({ color: 'azure4' })
const floor2Material = new THREE.MeshStandardMaterial({ color: 'limegreen' })
const obstacleMaterial = new THREE.MeshStandardMaterial({ color: 'orangered' })
const wallMaterial = new THREE.MeshStandardMaterial({ color: 'slategrey' })


export function BlockStart({position = [0,0,0]})
{
    return <group position={position}>
        <Float>
            <Text3D
                curveSegments={10}
                bevelEnabled
                bevelSize={0.03}
                bevelThickness={0.1}
                height={0.25}
                lineHeight={0.75}
                letterSpacing={0.01}
                size={0.2}
                position={[0.65,0.7,-1]}
                rotation-y={- 0.25}
                font="/Inter_Bold.json">
                {`Mountain\nClimber`}
                <meshNormalMaterial />
            </Text3D>
        </Float>
        <mesh castShadow geometry={simpleBox} material={floor1Material} position={[0,-0.1,0]} scale={[4,0.2,4]} receiveShadow />
        <CuboidCollider
            args={ [ 2, 0.1, 2  ] }
            position={ [ 0, -0.1, - (2) + 2 ] }
            restitution={ 0.2 }
            friction={ 1 }
        />
    </group>
}

export function BlockEnd({position = [0,0,0]})
{
    const hamburger = useGLTF('./hamburger.glb')

    hamburger.scene.children.forEach(mesh => {
        mesh.castShadow = true
    })

    return <group position={position}>
        <mesh castShadow geometry={simpleBox} material={floor1Material} position={[0,-0.1,0]} scale={[4,0.2,4]} receiveShadow />
        <RigidBody type="fixed" colliders="ball" position={[0,0.25,0]} restitution={0.2} friction={0}>
        <primitive object={hamburger.scene} scale={ 0.2 } />
        </RigidBody>
        <CuboidCollider
            args={ [ 2, 0.1, 2  ] }
            position={ [ 0, -0.1, - (2) + 2 ] }
            restitution={ 0.2 }
            friction={ 1 }
        />
    </group>
}

export function BlockSpinner({position = [0,0,0]})
{
    const obstacle = useRef()
    const [ speed ] = useState(()  => (Math.random() + 0.4 ) * (Math.random() < 0.5 ? - 1 : 1))

    useFrame((state) =>
    {
        const time = state.clock.getElapsedTime()
        
        const rotation = new THREE.Quaternion()
        rotation.setFromEuler(new THREE.Euler(0, time * speed, 0))
        obstacle.current.setNextKinematicRotation(rotation)
        
    })

    return <group position={position}>
        <mesh castShadow geometry={simpleBox} material={floor2Material} position={[0,-0.1,0]} scale={[4,0.2,4]} receiveShadow />
        <RigidBody ref={obstacle} type="kinematicPosition" position={[0,0.3,0]} restitution={0.2} friction={0} >
            <mesh geometry={simpleBox} material={obstacleMaterial} scale={[3.5,0.3,0.3]} castShadow receiveShadow/>
        </RigidBody>
        <CuboidCollider
            args={ [ 2, 0.1, 2  ] }
            position={ [ 0, -0.1, - (2) + 2 ] }
            restitution={ 0.2 }
            friction={ 1 }
        />
    </group>
}
export function BlockBar({position = [0,0,0]})
{
    const obstacle = useRef()
    const [ timeOffset ] = useState(()  => Math.random() * Math.PI * 2)

    useFrame((state) =>
    {
        const time = state.clock.getElapsedTime()
        
        const y = Math.sin(time + timeOffset) + 1.15
        obstacle.current.setNextKinematicTranslation({ x: position[0], y: position[1] + y, z: position[2] })
    })

    return <group position={position}>
        <mesh castShadow geometry={simpleBox} material={floor2Material} position={[0,-0.1,0]} scale={[4,0.2,4]} receiveShadow />
        <RigidBody ref={obstacle} type="kinematicPosition" position={[0,0.3,0]} restitution={0.2} friction={0} >
            <mesh geometry={simpleBox} material={obstacleMaterial} scale={[3.5,0.3,0.3]} castShadow receiveShadow/>
        </RigidBody>
        <CuboidCollider
            args={ [ 2, 0.1, 2  ] }
            position={ [ 0, -0.1, - (2) + 2 ] }
            restitution={ 0.2 }
            friction={ 1 }
        />
    </group>
}
export function BlockWall({position = [0,0,0]})
{
    const obstacle = useRef()
    const [ timeOffset ] = useState(()  => Math.random() * Math.PI * 2)

    useFrame((state) =>
    {
        const time = state.clock.getElapsedTime()
        
        const x = Math.sin(time + timeOffset) * 1.25
        obstacle.current.setNextKinematicTranslation({ x: position[0] + x, y: position[1] + 0.75, z: position[2] })
    })

    return <group position={position}>
        <mesh castShadow geometry={simpleBox} material={floor2Material} position={[0,-0.1,0]} scale={[4,0.2,4]} receiveShadow />
        <RigidBody ref={obstacle} type="kinematicPosition" position={[0,0.3,0]} restitution={0.2} friction={0} >
            <mesh geometry={simpleBox} material={obstacleMaterial} scale={[1.5,1.5,0.3]} castShadow receiveShadow/>
        </RigidBody>
        <CuboidCollider
            args={ [ 2, 0.1, 2  ] }
            position={ [ 0, -0.1, - (2) + 2 ] }
            restitution={ 0.2 }
            friction={ 1 }
        />
    </group>
}

function Wall({ length = 1 })
{
    return <>
        <RigidBody type="fixed" restitution={0.2} friction={0} >
            
            <mesh
                position={ [ - 2.15, 0.75, - (length * 2) + 2 ] }
                geometry={ simpleBox }
                material={ wallMaterial }
                scale={ [ 0.3, 1.5 * length, 4 * length ] }
                receiveShadow
            />
        </RigidBody>
    </>
}

export function Level({ count = 5, types = [ BlockSpinner, BlockWall, BlockBar ] })
{
    const blocks = useMemo(() => {
        const blocks = []
        
        for(let i = 0; i< count; i++)
        {
            const type = types[Math.floor(Math.random() * types.length)]
            blocks.push(type)
        }
        
        return blocks
    }, [ count, types] )

    return <>
        <BlockStart position={ [ 0, 0, 0 ] } />
        
        { blocks.map((Block, index) => <Block key={index} position={[0, index* 2, - (index + 1)* 8]} />) }

        <BlockEnd position={[0, (count)*2 , -(count + 1)* 8 ]} />

        <Wall length={ (count + 2)*2 } />
    </>
}